<template>
  <div v-if="!isFetchingFiles">
    <b-form-select :id="id" v-model="filePath" :state="state">
      <b-form-select-option disabled value=""
        >---Please upload file first---</b-form-select-option
      >
      <b-form-select-option
        v-for="option in cmsSiteFiles"
        :key="option.id"
        :value="option.publicPath"
        >{{ option.fileName }}
      </b-form-select-option>
    </b-form-select>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { v4 as uuid } from 'uuid'

export default {
  name: 'CmsFileSelect',
  props: {
    value: {
      type: String,
      default: () => '',
    },
    state: {
      type: Boolean,
      default: () => true,
    },
    id: {
      type: String,
      default: () => uuid(),
    },
  },
  data() {
    return {
      filePath: '',
    }
  },
  computed: {
    ...mapState({
      cmsSiteFiles: (state) => state.cms.cmsSiteFiles,
      isFetchingFiles: (state) => state.cms.isFetchingFiles,
      isFetchedCmsSiteFiles: (state) => state.cms.isFetchedCmsSiteFiles,
    }),
  },
  watch: {
    filePath(val) {
      this.$emit('update', val)
    },
    isFetchedCmsSiteFiles(val) {
      if (val) {
        this.filePath = this.value
      }
    },
  },
  created() {
    if (this.value) {
      this.filePath = this.value
    }
  },
}
</script>
